















import { Vue, Component, Ref } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import CollectionContent from './CollectionContent.vue'

@Component({ components: { PageTitle, CollectionContent } })
export default class VisitorRecords extends Vue {
  @Ref('unregistered') unregistered: CollectionContent
  @Ref('registered') registered: CollectionContent

  refresh() {
    this.unregistered && this.unregistered.fetchPersonList()
    this.registered && this.registered.fetchPersonList()
  }
}
